<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getCartLists()"
        style="width: 150px; margin-left: 10px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="exportExcel()" style="margin-left: 10px"
        >下载小推车订单表格</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
      :height="'calc(100vh - 150px)'"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="ename" label="使用展会"> </el-table-column>
      <el-table-column prop="orderno" label="小推车订单号"> </el-table-column>
      <el-table-column prop="amount" label="小推车数量"> </el-table-column>
      <el-table-column prop="total_price" label="总价"> </el-table-column>
      <el-table-column prop="company" label="公司名称"> </el-table-column>
      <el-table-column prop="contact" label="联系人"> </el-table-column>
      <el-table-column prop="phone" label="联系电话"> </el-table-column>
      <el-table-column prop="from_address" label="装货地址"> </el-table-column>
      <el-table-column prop="to_address" label="卸货地址"> </el-table-column>
      <el-table-column prop="memo" label="备注"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-show="scope.row.createtime">
            {{ formatDate(scope.row.createtime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope">
          <span v-show="scope.row.updatetime">
            {{ formatDate(scope.row.updatetime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="is_pay" label="订单支付状态">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.is_pay === '0'" type="warning"
            >待支付</el-tag
          >
          <el-tag v-show="scope.row.is_pay === '1'" type="success"
            >已支付</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="is_accept" label="员工接受状态">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.is_accept === '0'" type="warning"
            >待接收</el-tag
          >
          <el-tag v-show="scope.row.is_accept === '1'" type="success"
            >已接受
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="employee_name" label="处理员工"> </el-table-column>
      <el-table-column prop="employee_phone" label="处理电话">
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button type="primary" @click="checkMessage(scope.row)"
            >查看详情</el-button
          >
          <el-button type="info" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="数据删除后不可恢复！确定删除吗？"
            @confirm="deleteCartOrders(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 查看详情弹窗 -->
    <el-dialog title="查看详情" :visible.sync="dialogVisible" width="30%">
      <p>id:{{ detail.id }}</p>
      <p>小推车订单号:{{ detail.orderno }}</p>
      <p>下单会员号:{{ detail.user_id }}</p>
      <p>价格:{{ detail.price }}</p>
      <p>小推车数量:{{ detail.amount }}</p>
      <p>总价:{{ detail.total_price }}</p>
      <p>装货地址:{{ detail.from_address }}</p>
      <p>卸货地址:{{ detail.to_address }}</p>
      <p>订单创建时间:{{ formatDate(detail.createtime) }}</p>
      <p v-show="detail.updatetime">
        订单更新时间:{{ formatDate(detail.updatetime) }}
      </p>
      <p>审核人ID:{{ detail.reviewer_id }}</p>
      <p>
        订单当前状态:
        <span v-show="detail.status === 0">待支付</span>
        <span v-show="detail.status === 1">已下单待取件</span>
        <span v-show="detail.status === 2">运输中</span>
        <span v-show="detail.status === 3">已送达</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑小推车" :visible.sync="editDialogVisible" width="60%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="小推车单价">
              <el-input v-model="editPrice"></el-input>
            </el-form-item>
            <el-form-item label="小推车数量">
              <el-input v-model="editAmout"></el-input>
            </el-form-item>
            <el-form-item label="总价">
              <el-input v-model="editTotalPrice"></el-input>
            </el-form-item>
            <el-form-item label="装货地址">
              <el-input v-model="editFromAddress"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="卸货地址">
              <el-input v-model="editToAddress"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="editMemo"></el-input>
            </el-form-item>
            <el-form-item label="审核人ID">
              <el-input v-model="editReviewerId"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="editStatus">
                <el-radio :label="0">待支付</el-radio>
                <el-radio :label="1">下单待取件</el-radio>
                <el-radio :label="2">运输中</el-radio>
                <el-radio :label="3">已送达</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editCartOrders()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  deleteCartOrder,
  editCartOrder,
  getCartList,
  getEnameList,
} from "@/api/api";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      currentPage: 1,
      dialogVisible: false,
      editDialogVisible: false,
      detail: {},
      editId: "",
      editUserId: "",
      editPrice: "",
      editAmout: "",
      editTotalPrice: "",
      editFromAddress: "",
      editToAddress: "",
      editMemo: "",
      editCreatetime: 1,
      editUpdatetime: 1,
      editReviewerId: "",
      editStatus: "",
      enameList: [],
      ename: "",
    };
  },
  methods: {
    exportExcel() {
      // 定义字段与中文表头的映射
      const headerMap = {
        ename: "使用展会",
        orderno: "小推车订单号",
        amount: "小推车数量",
        total_price: "总价",
        company: "公司名称",
        contact: "联系人",
        phone: "联系电话",
        from_address: "装货地址",
        to_address: "卸货地址",
        memo: "备注",
        createtime: "创建时间",
        updatetime: "更新时间",
        is_pay: "订单支付状态",
        is_accept: "员工接受状态",
        employee_name: "处理员工",
        employee_phone: "处理电话",
      };

      // 处理数据，将字段转换为中文表头
      const formattedData = this.tableData.map((row) => {
        let newRow = {};
        Object.keys(headerMap).forEach((key) => {
          if (key === "is_pay") {
            // 订单支付状态转换
            newRow[headerMap[key]] =
              row[key] === "0" ? "待支付" : row[key] === "1" ? "已支付" : "";
          } else if (key === "is_accept") {
            // 员工接受状态转换
            newRow[headerMap[key]] =
              row[key] === "0" ? "待接收" : row[key] === "1" ? "已接受" : "";
          } else if (key === "createtime" || key === "updatetime") {
            // 时间字段格式化
            newRow[headerMap[key]] = row[key] ? this.formatDate(row[key]) : "";
          } else {
            // 其他字段直接赋值
            newRow[headerMap[key]] = row[key] || "";
          }
        });
        return newRow;
      });

      // 创建工作簿
      const wb = XLSX.utils.book_new();
      // 生成 Excel 表格
      const ws = XLSX.utils.json_to_sheet(formattedData, {
        header: Object.values(headerMap),
      });
      // 添加工作表
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出 Excel 文件
      XLSX.writeFile(wb, "小推车订单导出数据.xlsx");
    },

    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.enameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getCartLists(); // 请求新页面的数据
    },
    openEditDialog(row) {
      this.editId = row.id;
      this.editUserId = row.user_id;
      this.editPrice = row.price;
      this.editAmout = row.amount;
      this.editTotalPrice = row.total_price;
      this.editFromAddress = row.from_address;
      this.editToAddress = row.to_address;
      this.editMemo = row.memo;
      this.editCreatetime = row.createtime;
      this.editUpdatetime = moment().unix();
      this.editReviewerId = row.reviewer_id;
      this.editStatus = row.status;
      this.editDialogVisible = true;
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getCartLists();
    },
    checkMessage(row) {
      this.detail = row;
      this.dialogVisible = true;
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getCartLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getCartList(token, this.page, this.pageSize, this.ename).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    // getCartLists() {
    //   this.loading = true;
    //   const token = localStorage.getItem("token");
    //   getCartList(token, this.page, this.list).then((res) => {
    //     if (res.data.code === 200) {
    //       this.tableData = res.data.data.data;
    //       this.total = res.data.data.total;
    //       this.loading = false;
    //     } else {
    //       this.$message.error(res.data.msg);
    //       this.loading = false;
    //     }
    //   });
    // },
    deleteCartOrders(id) {
      const token = localStorage.getItem("token");
      deleteCartOrder(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getCartLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    editCartOrders() {
      const token = localStorage.getItem("token");
      editCartOrder(
        token,
        this.editId,
        this.editUserId,
        this.editPrice,
        this.editAmout,
        this.editTotalPrice,
        this.editFromAddress,
        this.editToAddress,
        this.editMemo,
        this.editCreatetime,
        this.editUpdatetime,
        this.editReviewerId,
        this.editStatus
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.getCartLists();
          this.editDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
          this.editDialogVisible = false;
        }
      });
    },
  },
  created() {
    this.getCartLists();
    this.getEnameLists();
  },
};
</script>
