<template>
  <div>
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <!-- <el-table-column prop="userid" label="下单用户ID"></el-table-column> -->
      <el-table-column prop="orderno" label="订单号"></el-table-column>
      <el-table-column prop="cname" label="企业名称"></el-table-column>
      <el-table-column prop="contact" label="联系人"></el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="ename" label="原展会名称"></el-table-column>
      <el-table-column prop="ehall" label="原展馆"></el-table-column>
      <el-table-column prop="eloc" label="原展位"></el-table-column>
      <el-table-column prop="withdraw_time" label="取件时间"></el-table-column>
      <el-table-column prop="new_ename" label="新展会名称"></el-table-column>
      <el-table-column prop="new_ehall" label="新展馆"></el-table-column>
      <el-table-column prop="new_eloc" label="新展位"></el-table-column>
      <el-table-column prop="new_contact" label="新联系人"></el-table-column>
      <el-table-column prop="new_phone" label="新联系方式"></el-table-column>
      <el-table-column prop="send_time" label="派件时间"></el-table-column>
      <el-table-column prop="memo" label="备注"></el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <!-- 			<el-table-column prop="is_pay" label="是否支付">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.is_pay == 1" type="success">已支付</el-tag>
					<el-tag v-else type="warning">未支付</el-tag>
				</template>
			</el-table-column> -->
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getIntservOrder } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getIntservOrders(); // 请求新页面的数据
    },
    getIntservOrders() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getIntservOrder(token, this.page, this.pageSize, this.ename).then(
        (res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
            console.log(this.orderList);
          } else {
            this.$message.error("获取订单失败！" + res.data.msg);
            this.loading = false;
          }
        }
      );
    },
  },
  created() {
    this.getIntservOrders();
  },
};
</script>

<style></style>
