<!-- 现场服务单 -->
<template>
  <div>
    <div style="margin: 0px 0px 10px 10px">
      <el-select
        v-model="orderStats"
        clearable
        placeholder="请选择订单状态"
        @change="getExhibitOrders()"
        style="width: 150px"
      >
        <el-option
          v-for="item in orderStatsList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getEnameAll()"
        style="width: 150px; margin-left: 10px"
      >
        <el-option
          v-for="item in exhibitionNameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-input
        suffix-icon="el-icon-search"
        v-model="ebusiness"
        placeholder="请输入公司名称"
        style="width: 200px; margin-left: 10px"
      />
      <el-input
        suffix-icon="el-icon-search"
        v-model="orderno"
        placeholder="请输入订单号"
        style="width: 200px; margin-left: 10px"
      />
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="searchExhibitOrders()"
        >搜索</el-button
      >
      <!-- <el-button type="warning" @click="chooseOrderDialogVisible = true"
        >开具纸质订单</el-button
      > -->
      <el-button
        type="success"
        style="margin-left: 10px"
        @click="createDialogVisible = true"
        >创建现场服务单</el-button
      >
      <el-button type="primary" @click="exportTb = true"
        >导出展会订单</el-button
      >
      <el-button
        type="warning"
        @click="batchPrintOrders"
        :disabled="selectedOrders.length === 0 || batchPrintLoading"
        :loading="batchPrintLoading"
      >
        批量开具纸质订单
      </el-button>
    </div>
    <!--  数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="Loading"
      @selection-change="handleSelectionChange"
      border
      stripe
      style="width: 100%"
      :height="'calc(100vh - 150px)'"
    >
      <el-table-column type="selection"></el-table-column>
      <!-- <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="user_id" label="下单用户id"> </el-table-column> -->
      <el-table-column prop="orderno" label="订单号"></el-table-column>
      <el-table-column
        prop="exhibition_name"
        label="参展展会名称"
      ></el-table-column>
      <el-table-column
        prop="business_exhibition_name"
        label="参展展商名称"
      ></el-table-column>
      <el-table-column prop="contacts" label="联系人"></el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
      <el-table-column
        prop="exhibition_hall"
        label="参展展馆"
      ></el-table-column>
      <el-table-column
        prop="exhibition_location"
        label="展位号"
      ></el-table-column>
      <el-table-column prop="type" label="业务类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">现场装卸货</span>
          <span v-if="scope.row.type === 2">仓库货</span>
          <span v-if="scope.row.type === 3">空箱存储</span>
          <span v-if="scope.row.type === 4">其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="license" label="入展车牌"></el-table-column>
      <el-table-column prop="money" label="订单金额"></el-table-column>
      <el-table-column prop="memo" label="备注"></el-table-column>
      <el-table-column prop="user_id" label="订单是否被用户领取">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.user_id === null" type="danger"
            >未领取</el-tag
          >
          <el-tag v-else type="success">已领取</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_complete" label="订单是否完整">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_complete === 0" type="danger"
            >不完整</el-tag
          >
          <el-tag v-if="scope.row.is_complete === 1" type="success"
            >完整</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="is_pay" label="订单是否付款">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_pay === 0" type="danger">未付款</el-tag>
          <el-tag v-if="scope.row.is_pay === 1" type="success">已付款</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_accept" label="是否已被接受">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_accept === '1'"
            >已接受</el-tag
          >
          <el-tag type="danger" v-if="scope.row.is_accept === '0'"
            >未接受</el-tag
          >
          <el-tag type="danger" v-if="scope.row.is_accept === null"
            >未接受</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="is_done" label="订单是否已完成">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_done === '1'"
            >已完成</el-tag
          >
          <el-tag type="danger" v-if="scope.row.is_done === '0'">未完成</el-tag>
          <el-tag type="danger" v-if="scope.row.is_done === null"
            >未完成</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="订单创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="订单修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="small"
            v-if="scope.row.user_id === null"
            type="success"
            @click="openSendDialog(scope.row.orderno)"
            style="margin-left: 10px"
            >派单至用户</el-button
          >
          <el-button
            size="small"
            v-else
            type="success"
            style="margin-left: 10px"
            disabled
            >派单至用户</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="getExhibitOrderGood(scope.row)"
            >查看订单货物数据</el-button
          >
          <el-button
            size="small"
            type="warning"
            @click="openSendEmployeeDialog(scope.row)"
            style="margin-top: 10px"
            >指派订单</el-button
          >
          <el-button
            size="small"
            type="success"
            @click="openIsPayDialog(scope.row.orderno)"
            >已付款</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="openSetPriceDialog(scope.row)"
            style="margin-top: 10px"
            >设置订单价格</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="checkOrderRepEmps(scope.row.orderno)"
            >查看负责员工</el-button
          >
          <el-button
            size="small"
            type="info"
            @click="(selectedOrder = scope.row.orderno), searchExhibitOrderss()"
            style="margin-top: 10px"
            >开具纸质订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getExhibitOrders()"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>

          <!-- 显示第一页 -->
          <div
            @click="changePage(1)"
            :class="{ 'active-page': currentPage === 1 }"
            class="page-button"
          >
            1
          </div>

          <!-- 显示省略号 -->
          <span v-if="showLeftEllipsis">...</span>

          <!-- 显示当前页附近的页码 -->
          <div
            v-for="page in filteredPages"
            :key="page"
            @click="changePage(page)"
            :class="{ 'active-page': currentPage === page }"
            class="page-button"
          >
            {{ page }}
          </div>

          <!-- 显示省略号 -->
          <span v-if="showRightEllipsis">...</span>

          <!-- 显示最后一页 -->
          <div
            @click="changePage(total)"
            :class="{ 'active-page': currentPage === total }"
            class="page-button"
          >
            {{ total }}
          </div>

          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 显示总览 -->
    <div v-if="ename" style="color: #666666">
      <p>
        &nbsp;&nbsp;现场服务订单总体积：{{
          volumes["1"]
        }}&nbsp;&nbsp;仓库订单总体积{{ volumes["2"] }}&nbsp;&nbsp;
        空箱订单总体积{{ volumes["3"] }}
      </p>
    </div>
    <!-- 纸质订单选择窗口 -->
    <el-dialog
      title="请选择订单"
      :visible.sync="chooseOrderDialogVisible"
      width="50%"
    >
      <div v-for="(item, index) in tableData" :key="index">
        <el-radio v-model="selectedOrder" :label="item.orderno">
          {{ item.orderno }}&nbsp;&nbsp;联系人姓名:{{
            item.contacts
          }}&nbsp;&nbsp;公司名称：{{ item.business_exhibition_name }}
        </el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chooseOrderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="searchExhibitOrderss()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 创建现场服务单 -->
    <el-dialog
      title="创建现场服务单"
      :visible.sync="createDialogVisible"
      width="80%"
    >
      <el-row :gutter="20">
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="选择展会">
              <el-radio-group
                v-model="exhibitionName"
                @change="handleRadioChange"
              >
                <el-radio
                  v-for="item in exhibitionNameList"
                  :key="item.id"
                  :label="item.label"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择展馆">
              <el-radio-group
                v-model="exhibitionHall"
                v-for="item in exhibitionHallList"
                :key="item.id"
              >
                <el-radio :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="展商名称">
              <el-input v-model="businessExhibitionName"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名">
              <el-input v-model="contacts"></el-input>
            </el-form-item>
            <el-form-item label="展位号">
              <el-input v-model="exhibitionLocation"></el-input>
            </el-form-item>
            <el-form-item label="联系人电话">
              <el-input v-model="phone"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form>
            <el-form-item label="车牌号">
              <el-input v-model="license"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="memo"></el-input>
            </el-form-item>
            <el-form-item label="业务类型">
              <el-radio-group v-model="type">
                <el-radio :label="1">现场装卸货</el-radio>
                <el-radio :label="2">仓库货</el-radio>
                <el-radio :label="3">空箱</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createServiceOrders()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 货物信息查看 -->
    <el-dialog title="货物信息查看" :visible.sync="dialogVisible" width="80%">
      <el-button
        type="success"
        style="margin-bottom: 10px"
        @click="addDialogVisible = true"
        >添加货物信息</el-button
      >
      <el-table :data="goods" border stripe style="width: 100%">
        <el-table-column prop="orderno" label="订单id"></el-table-column>
        <el-table-column prop="goodsid" label="货物id"></el-table-column>
        <el-table-column prop="length" label="长度"></el-table-column>
        <el-table-column prop="width" label="宽度"></el-table-column>
        <el-table-column prop="height" label="高度"></el-table-column>
        <el-table-column prop="weight" label="重量"></el-table-column>
        <el-table-column prop="count" label="数量"></el-table-column>
        <el-table-column prop="cbm" label="体积"></el-table-column>
        <el-table-column prop="totalcbm" label="总体积"></el-table-column>
        <el-table-column prop="totalweight" label="总重量"></el-table-column>
        <el-table-column prop="packages" label="包装方式">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.packages === '' ||
                scope.row.packages === null ||
                !scope.row.packages
              "
              >无包装</span
            >
            <span v-if="scope.row.packages === 0">无包装</span>
            <span v-if="scope.row.packages === 1">木箱</span>
            <span v-if="scope.row.packages === 2">木托</span>
            <span v-if="scope.row.packages === 3">纸箱</span>
            <span v-if="scope.row.packages === 4">其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="memo" label="备注"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" @click="openEditDialog(scope.row)"
              >修改</el-button
            >
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="deleteGoodsItems(scope.row)"
            >
              <el-button
                slot="reference"
                type="danger"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改货物信息弹窗 -->
    <el-dialog
      title="修改货物信息"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <span>当前正在编辑的订单号：{{ editOrderno }}</span>
      <span>当前正在编辑的货物ID：{{ editGoodsid }}</span>
      <el-row gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="宽度">
              <el-input v-model="editWidth"></el-input>
            </el-form-item>
            <el-form-item label="高度">
              <el-input v-model="editHeight"></el-input>
            </el-form-item>
            <el-form-item label="长度">
              <el-input v-model="editLength"></el-input>
            </el-form-item>
            <el-form-item label="体积">
              <el-input v-model="editCbm"></el-input>
            </el-form-item>
            <el-form-item label="重量">
              <el-input v-model="editWeight"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0" style="padding-top: 0px">
          <el-form>
            <el-form-item label="货物数量">
              <br />
              <el-input-number
                v-model="editCount"
                :min="1"
                label="描述文字"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="包装方式">
              <span>{{ editPackages }}</span>
              <el-radio-group v-model="editPackages">
                <el-radio :label="0">无包装</el-radio>
                <el-radio :label="1">木箱</el-radio>
                <el-radio :label="2">木托</el-radio>
                <el-radio :label="3">纸箱</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="editMemo"></el-input>
            </el-form-item>
            <el-form-item label="总体积">
              <el-input v-model="editTotalCbm" disabled></el-input>
            </el-form-item>
            <el-form-item label="总重量">
              <el-input v-model="editTotalWeight" disabled></el-input>
            </el-form-item>
            <el-button type="primary" @click="setTotal"
              >计算总体积总重量</el-button
            >
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateGoodsItems()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加货物信息弹窗 -->
    <el-dialog
      title="添加货物信息"
      :visible.sync="addDialogVisible"
      width="60%"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="货物长度">
              <el-input v-model="addLength"></el-input>
            </el-form-item>
            <el-form-item label="货物宽度">
              <el-input v-model="addWidth"></el-input>
            </el-form-item>
            <el-form-item label="货物高度">
              <el-input v-model="addHeight"></el-input>
            </el-form-item>
            <el-form-item label="货物重量">
              <el-input v-model="addWeight"></el-input>
            </el-form-item>
            <el-form-item label="货物数量">
              <br />
              <el-input-number v-model="addCount" :min="1"></el-input-number>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="货物体积">
              <el-input v-model="addCbm"></el-input>
            </el-form-item>
            <el-form-item label="货物总体积">
              <el-input v-model="addTotalCbm" disabled></el-input>
            </el-form-item>
            <el-form-item label="货物总重量">
              <el-input v-model="addTotalWeight" disabled></el-input>
            </el-form-item>
            <el-form-item label="包装方式">
              <br />
              <el-radio-group v-model="addPackages">
                <el-radio :label="0">无包装</el-radio>
                <el-radio :label="1">木箱</el-radio>
                <el-radio :label="2">木托</el-radio>
                <el-radio :label="3">纸箱</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="addMemo"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button
          v-if="addTotalCbm || addTotalWeight"
          type="primary"
          @click="completeServiceOrders()"
          >确 定</el-button
        >
        <el-button
          v-if="!addTotalCbm || !addTotalWeight"
          type="primary"
          @click="setTotalCbm()"
          >计算体积与重量</el-button
        >
      </span>
    </el-dialog>
    <!-- 指派订单弹窗 -->
    <el-dialog
      title="指派订单"
      :visible.sync="sendOrderDialogVisible"
      width="30%"
    >
      <p>
        当前指派的订单号为:
        <span style="font-weight: bolder">{{ sendOrderNo }}</span>
      </p>
      <el-input placeholder="请输入员工姓名" v-model="sendSearch">
        <el-button
          style="padding-right: 10px"
          slot="suffix"
          type="text"
          @click="searchEmployees()"
          >搜索员工</el-button
        >
      </el-input>
      <!-- <el-radio-group
        v-for="item in sendEmployeeList"
        :key="item.id"
        v-model="chooseEmployee"
      >
        <el-radio :label="item.phone"
          >{{ item.nickname }}&nbsp;{{ item.phone }}</el-radio
        >
      </el-radio-group> -->
      <el-checkbox-group
        v-for="item in sendEmployeeList"
        :key="item.id"
        v-model="chooseEmployee"
      >
        <el-checkbox :label="item.phone"
          >{{ item.nickname }}&nbsp;{{ item.phone }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendOrderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendOrderEmployee()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 订单已付款提醒 -->
    <el-dialog
      title="订单状态修改确认"
      :visible.sync="ispayDialogVisible"
      width="30%"
      center
    >
      <p>您正在修改订单{{ payorderno }}的状态为已付款，请核对！继续操作吗？</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ispayDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderIsPays()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 订单已付款提醒 -->
    <el-dialog
      title="订单价格修改"
      :visible.sync="priceDialogVisible"
      width="30%"
      center
    >
      <p>
        您正在修改订单
        <span style="font-weight: bolder">{{ setOrderno }}</span>
        的价格
      </p>
      <p>当前订单价格：{{ orderMoney }}</p>
      <el-input v-model="price" placeholder="请输入您要修改的价格"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setPrices()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 派单弹窗 -->
    <el-dialog title="派单弹窗" :visible.sync="sendVisible" width="30%">
      <p>您正在指定订单{{ sendOrderno }}的归属用户</p>
      <el-form>
        <el-form-item label="归属账户">
          <el-input v-model="sphone">
            <el-button @click="getUser()" type="text" slot="suffix"
              >搜索账户</el-button
            >
          </el-input>
          <el-radio-group
            v-model="ephone"
            v-for="item in userList"
            :key="item.id"
          >
            <el-radio :label="item.id">{{ item.mobile }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendOrderToUsers()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出订单弹窗 -->
    <el-dialog title="导出订单为表格" :visible.sync="exportTb" width="30%">
      <!-- <el-input
        v-model="enameKey"
        placeholder="请输入展会名称"
        @change="getEnameListForKeys()"
      ></el-input> -->
      <!-- <el-select
        v-model="tbEname"
        clearable
        placeholder="请选择展会"
        style="width: 100%; margin-top: 10px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select> -->
      <el-select
        v-model="tbEname"
        filterable
        placeholder="请选择展会"
        style="width: 100%"
      >
        <el-option
          v-for="item in exhibitionNameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportTb = false">取 消</el-button>
        <el-button type="success" @click="exportCSVs()"
          >导出订单为Excel</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看负责员工弹窗 -->
    <el-dialog title="查看负责员工" :visible.sync="empRepDialog" width="80%">
      <el-table :data="empRep" v-loading="empRepLoading" style="width: 100%">
        <el-table-column prop="orderno" label="订单号"></el-table-column>
        <el-table-column
          prop="employee_name"
          label="负责员工姓名"
        ></el-table-column>
        <el-table-column
          prop="employee_phone"
          label="负责员工电话"
        ></el-table-column>
        <el-table-column prop="status" label="订单状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="success">已完成</el-tag>
            <el-tag v-else type="danger">未完成</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 纸质订单预览窗口 -->
    <el-dialog
      title="纸质订单预览窗口"
      :visible.sync="orderDialogVisible"
      width="53%"
    >
      <div
        ref="pdfContent"
        id="pdfContent"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          font-family: 'Times New Roman', Times, serif;
        "
      >
        <!-- 内容部分 -->
        <div>
          <div style="border-top: 1px black solid"></div>
          <el-row>
            <el-col :span="8" :offset="0">&nbsp;</el-col>
            <el-col :span="8" :offset="0">
              <p style="margin: 5px; font-size: 20px">现场操作单</p>
              <p style="margin: 0">ON-SITE-ORDER FORM</p>
            </el-col>
            <el-col :span="8" :offset="0">
              <BarCode :value="orderData.orderno" :options="barcodeOptions" />
            </el-col>
          </el-row>
          <!-- 信息展示部分 -->
          <el-row gutter="30">
            <el-col span="12" style="font-size: 13px">
              <!-- 展览会名称 -->
              <el-row>
                <el-col :span="6" :offset="0">
                  <p style="margin: 0">展览会名称</p>
                  <p style="margin: 0">EXHIBITION</p>
                </el-col>
                <el-col :span="18" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.exhibition_name || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
              <!-- 展商 -->
              <el-row>
                <el-col :span="6" :offset="0">
                  <p style="margin: 0">展商</p>
                  <p style="margin: 0">EXHIBITOR</p>
                </el-col>
                <el-col :span="18" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.business_exhibition_name || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
              <!-- 代理 -->
              <el-row>
                <el-col :span="6" :offset="0">
                  <p style="margin: 0">代理</p>
                  <p style="margin: 0">AGENT</p>
                </el-col>
                <el-col :span="18" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.agent || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
              <!-- 联系人 -->
              <el-row>
                <el-col :span="6" :offset="0">
                  <p style="margin: 0">联系人</p>
                  <p style="margin: 0">CONTACT</p>
                </el-col>
                <el-col :span="18" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.contacts || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col span="12" style="font-size: 13px; text-align: left">
              <!-- 订单号 -->
              <el-row>
                <el-col :span="2" :offset="0">
                  <p style="margin: 7px 0 0 0; font-size: 15px">NO.</p>
                </el-col>
                <el-col :span="22" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.orderno || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
              <!-- 展馆展台号 -->
              <el-row gutter="20">
                <el-col span="10">
                  <el-row>
                    <el-col :span="7" :offset="0">
                      <p style="margin: 0">展馆</p>
                      <p style="margin: 0">HALL</p>
                    </el-col>
                    <el-col :span="17" :offset="0">
                      <div style="border-bottom: 1px black solid; width: 100%">
                        <p
                          style="margin: 5px; transform: translateY(5px)"
                          v-html="orderData.exhibition_hall || '&nbsp;'"
                        ></p>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col span="14">
                  <el-row>
                    <el-col :span="7" :offset="0">
                      <p style="margin: 0">展台号</p>
                      <p style="margin: 0">BOOTH</p>
                    </el-col>
                    <el-col :span="17" :offset="0">
                      <div style="border-bottom: 1px black solid; width: 100%">
                        <p
                          style="margin: 5px; transform: translateY(5px)"
                          v-html="orderData.exhibition_location || '&nbsp;'"
                        ></p>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <!-- 车牌号 -->
              <el-row>
                <el-col :span="7" :offset="0">
                  <p style="margin: 0">车牌号</p>
                  <p style="margin: 0">PLATENUMBER</p>
                </el-col>
                <el-col :span="17" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.license || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
              <!-- 手机号 -->
              <el-row>
                <el-col :span="4" :offset="0">
                  <p style="margin: 0">手机号</p>
                  <p style="margin: 0">PHONE</p>
                </el-col>
                <el-col :span="20" :offset="0">
                  <div style="border-bottom: 1px black solid; width: 100%">
                    <p
                      style="margin: 5px; transform: translateY(5px)"
                      v-html="orderData.phone || '&nbsp;'"
                    ></p>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <!-- 表格部分 -->
          <div style="text-align: left">
            <p>服务项目 SERVICE</p>
            <div class="tb">
              <p>
                序号
                <br />
                <span>#</span>
              </p>
              <p>
                长
                <br />
                <span>(L)</span>
              </p>
              <p>
                宽
                <br />
                <span>(W)</span>
              </p>
              <p>
                高
                <br />
                <span>(H)</span>
              </p>
              <p>
                数量
                <br />
                <span>QTY</span>
              </p>
              <p>
                重量
                <br />
                <span>Weight</span>
              </p>
              <p>
                体积
                <br />
                <span>Volum</span>
              </p>
              <p>
                仓库进馆
                <br />
                <span>VIA W/H</span>
              </p>
              <p>
                进馆操作
                <br />
                <span>Inbound</span>
              </p>
              <p>
                出馆操作
                <br />
                <span>Outbound</span>
              </p>
              <p>
                是否拆箱
                <br />
                <span>Unpack</span>
              </p>
              <p>
                空箱保管
                <br />
                <span>Empty</span>
              </p>
              <p>
                管理费
                <br />
                <span>Manage</span>
              </p>
              <p>
                机力费
                <br />
                <span>Mechanical</span>
              </p>
              <p>
                其他费用
                <br />
                <span>Other</span>
              </p>
              <p>
                货物费
                <br />
                <span>Money</span>
              </p>
            </div>
            <div class="tbc" v-for="(item, index) in goodsList" :key="index">
              <p class="tbmain">{{ index + 1 }}</p>
              <p class="tbmain" v-html="item.length || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.width || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.height || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.qty || '&nbsp;'"></p>
              <p
                class="tbmain"
                v-html="item.weight || '&nbsp;'"
                style="margin-left: 10px"
              ></p>
              <p class="tbmain" v-html="item.volum || '&nbsp;'"></p>
              <p class="tbmain" style="margin-left: 40px">
                {{ orderType === 2 || orderType === "2" ? "✓" : "\u00A0" }}
              </p>
              <p class="tbmain" v-html="item.Inbound || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.Outbound || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.Unpack || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.empty || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.mange || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.Mechanical || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.Other || '&nbsp;'"></p>
              <p class="tbmain" v-html="item.Money || '&nbsp;'"></p>
              <!-- <div style="transform: translate(-450px, 40px)">
                  <p style="margin: 0; font-size: 12px">
                    <span style="margin-left: 50px">总计</span>
                    <span style="margin-left: 5px">TOTAL</span>
                    <span style="margin-left: 10px">{{
                      item.count || ""
                    }}</span>
                    <span style="margin-left: 30px">{{
                      item.totalcbm || ""
                    }}</span>
                    <span style="margin-left: 35px">{{
                      item.totalweight || ""
                    }}</span>
                  </p>
                </div> -->
            </div>
            <div>
              <p style="margin: 0; font-size: 12px">
                <span style="margin-left: 50px">总计</span>
                <span style="margin-left: 5px">TOTAL</span>
                <span style="margin-left: 10px">{{ totalCount || "" }}</span>
                <span style="margin-left: 30px">{{ totalCbm || "" }}</span>
                <span style="margin-left: 35px">{{ totalWeight || "" }}</span>
                <span style="margin-left: 35px"
                  >税前费用：{{ (money / (1 + 0.06)).toFixed(2) || "" }}</span
                >
                <span style="margin-left: 35px"
                  >总计费用：{{ money || "" }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <!-- footer部分 -->
        <div>
          <el-row :gutter="20" style="font-size: 12px; text-align: left">
            <el-col :span="10" :offset="0">
              <p style="margin: 10px 0 0 0">展商/代理签字</p>
              <p style="margin: 0">SIGNED ON BEHALF OF EXHIBITOR/AGENT</p>
              <div style="border-bottom: 1px black dashed">
                <h1
                  style="font-family: 楷体; margin: 10px 0 0 0"
                  v-html="orderData.contacts || '&nbsp;'"
                ></h1>
              </div>
            </el-col>
            <el-col :span="10" :offset="0">
              <p style="margin: 10px 0 0 0">金泉项目员签字</p>
              <p style="margin: 0">SIGNATURE OF PROJECT MANAGER</p>
              <div style="border-bottom: 1px black dashed">
                <p style="margin: 20px 0 0 0">李四 10086</p>
              </div>
            </el-col>
            <el-col :span="4" :offset="0">
              <p style="margin: 10px 0 0 0">日期</p>
              <p style="margin: 0">DATE</p>
              <div style="border-bottom: 1px black dashed">
                <p style="margin: 23px 0 0 0">{{ currentDate }}</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="orderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="generatePDF()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  createServiceOrder,
  deleteGoodsItem,
  getEhalls,
  getEnameList,
  getExhibitOrder,
  getExhibitOrderGoods,
  getExhibitOrderGoodss,
  getUsers,
  searchExhibitOrder,
  updateGoodsItem,
  searchEmployee,
  sendOrderEmployee,
  orderIsPay,
  setPrice,
  sendOrderToUser,
  completeServiceOrder,
  exportCSV,
  getEnameTotalCbm,
  checkOrderRepEmp,
  getEnameListForKey,
} from "@/api/api";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BarCode from "./BarCode.vue";

export default {
  components: {
    BarCode,
  },
  data() {
    return {
      barcodeOptions: {
        format: "CODE128",
        lineColor: "#000000",
        width: 1,
        height: 20,
        displayValue: false,
      },
      tableData: [],
      Loading: false,
      dialogVisible: false,
      page: 1,
      list: 20,
      total: 0, // 总页数
      pageRange: 3, // 当前页前后显示的页数范围
      currentPage: 1,
      goods: [],
      orderno: "",
      ebusiness: "",
      editOrderno: "",
      editGoodsid: "",
      editWidth: "",
      editLength: "",
      editHeight: "",
      editWeight: "",
      editCount: "",
      editCbm: "",
      editTotalCbm: "",
      editTotalWeight: "",
      editPackages: "",
      editMemo: "",
      editDialogVisible: false,
      orderDialogVisible: false,
      currentDate: "",
      selectedRows: [],
      orderList: [],
      chooseOrderDialogVisible: false,
      selectedOrder: "",
      orderData: [],
      goodsList: [],
      exhibitionNameList: [],
      exhibitionName: "",
      exhibitionHallList: [],
      exhibitionHall: "",
      selectedEname: "",
      selectedEid: "",
      userList: [],
      phone: "",
      sphone: "",
      ephone: "",
      createDialogVisible: false,
      contacts: "",
      businessExhibitionName: "",
      type: "",
      license: "",
      memo: "",
      exhibitionLocation: "",
      addOrderNo: "",
      addDialogVisible: false,
      addWidth: "",
      addLength: "",
      addHeight: "",
      addWeight: "",
      addCount: "",
      addCbm: "",
      addTotalCbm: "",
      addTotalWeight: "",
      addPackages: "",
      addMemo: "",
      totalCount: "",
      totalCbm: "",
      totalWeight: "",
      money: "",
      orderType: "",
      sendOrderDialogVisible: false,
      sendOrderNo: "",
      sendEmployee: "",
      sendSearch: "",
      sendEmployeeList: [],
      chooseEmployee: [],
      ispayDialogVisible: false,
      payorderno: "",
      setOrderno: "",
      orderMoney: "",
      price: "",
      priceDialogVisible: false,
      ename: "",
      orderStatsList: [
        {
          value: "1",
          label: "不完整订单",
        },
        {
          value: "2",
          label: "未付款订单",
        },
        {
          value: "3",
          label: "未接受订单",
        },
        {
          value: "4",
          label: "未完成订单",
        },
        {
          value: "5",
          label: "已完成订单",
        },
      ],
      orderStats: "",
      sendVisible: false,
      sendOrderno: "",
      volumes: [],
      exportTb: false,
      tbEname: "",
      empRep: [],
      empRepLoading: false,
      empRepDialog: false,
      enameList: [],
      enameKey: "",
      selectedOrders: [], // 存储多选的订单
      multipleOrderData: [], // 存储多个订单的数据
      currentPrintIndex: 0, // 当前打印的订单索引
      batchPrintLoading: false, // 批量打印的loading状态
    };
  },
  methods: {
    checkOrderRepEmps(orderno) {
      this.empRepLoading = true;
      this.empRepDialog = true;
      checkOrderRepEmp(orderno).then((res) => {
        if (res.data.code === 200) {
          this.empRep = res.data.data;
          this.empRepLoading = false;
        } else {
          this.$message.error("获取数据失败！");
        }
      });
    },
    getEnameAll() {
      this.getExhibitOrders();
      this.getEnameTotalCbms();
    },
    getEnameTotalCbms() {
      getEnameTotalCbm(this.ename).then((res) => {
        if (res.data.code === 200) {
          this.volumes = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    exportCSVs() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      exportCSV(this.tbEname) // 调用封装好的函数，传递参数
        .then((response) => {
          // 创建一个临时 URL 并下载文件
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.tbEname + "订单导出.csv"); // 设置下载文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          loading.close();
          this.exportTb = false;
        })
        .catch((error) => {
          console.error("导出 CSV 失败: ", error);
          loading.close();
        });
    },
    // 添加弹窗计算体积和重量
    setTotalCbm() {
      this.addTotalCbm = this.addLength * this.addWidth * this.addHeight;
      this.addTotalWeight = this.addWeight * this.addCount;
    },
    completeServiceOrders() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      completeServiceOrder(
        token,
        this.addOrderNo,
        this.addWidth,
        this.addLength,
        this.addHeight,
        this.addWeight,
        this.addCount,
        this.addCbm,
        this.addTotalCbm,
        this.addTotalWeight,
        this.addPackages,
        this.addMemo
      )
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.addDialogVisible = false;
            this.getExhibitOrders();
            this.getExhibitOrderGoods();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          // this.$message.error(res.data.msg);
          console.log(res.msg);
          loading.close();
        });
    },
    sendOrderToUsers() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const token = localStorage.getItem("token");
      sendOrderToUser(token, this.sendOrderno, this.ephone)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.sendVisible = false;
            this.getExhibitOrders();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    openSendDialog(orderno) {
      this.sendOrderno = orderno;
      this.sendVisible = true;
    },
    // searchOrderForEnames(){
    //   const token = localStorage.getItem("token");
    //   searchOrderForEname(token,this.ename).then((res)=>{
    //     if(res.data.code === 200){

    //     }
    //   })
    // },
    setPrices() {
      const loading = this.$loading({
        lock: true,
        text: "正在修改订单",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      setPrice(token, this.setOrderno, this.price)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改价格成功！");
            this.getExhibitOrders();
            this.priceDialogVisible = false;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    openSetPriceDialog(row) {
      if (row.is_pay === 1 || row.is_done === "1") {
        this.$message.error("订单已付款或已完成，无法修改价格！");
      } else if (row.is_complete === 0) {
        this.$message.error("货物信息不完整，无法修改价格！");
      } else {
        this.setOrderno = row.orderno;
        this.orderMoney = row.money;
        this.priceDialogVisible = true;
      }
    },
    orderIsPays() {
      const loading = this.$loading({
        lock: true,
        text: "正在修改订单",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      orderIsPay(token, this.payorderno).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("订单状态修改成功！");
          this.getExhibitOrders();
          loading.close();
          this.ispayDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
          this.getExhibitOrders();
          loading.close();
          this.ispayDialogVisible = false;
        }
      });
    },
    openIsPayDialog(orderno) {
      this.payorderno = orderno;
      this.ispayDialogVisible = true;
    },
    sendOrderEmployee() {
      const loading = this.$loading({
        lock: true,
        text: "正在推送订单",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      sendOrderEmployee(token, this.sendOrderNo, this.chooseEmployee)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("指派成功!订单消息已经推送至用户！");
            this.getExhibitOrders();
            loading.close();
            this.sendOrderDialogVisible = false;
          } else {
            this.$message.error(res.data.msg);
            this.getExhibitOrders();
            loading.close();
            this.sendOrderDialogVisible = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.getExhibitOrders();
          loading.close();
          this.sendOrderDialogVisible = false;
        });
    },
    searchEmployees() {
      const loading = this.$loading({
        lock: true,
        text: "正在搜索员工信息",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      searchEmployee(token, this.sendSearch).then((res) => {
        if (res.data.code === 200) {
          this.sendEmployeeList = res.data.data;
          loading.close();
        } else {
          loading.close();
          this.$message.error(res.data.msg);
        }
      });
    },
    openSendEmployeeDialog(row) {
      if (row.is_complete === 0) {
        this.$message.error("订单信息不完整！无法操作");
      } else if (row.is_done === "1") {
        this.$message.error("订单已完成！无法操作");
      } else if (row.is_accept === "1") {
        this.$message.error("订单已被接受！无法操作");
      } else if (row.is_pay === 0) {
        this.$message.error("订单未支付！无法操作");
      } else if (row.is_pay === 1) {
        this.sendOrderNo = row.orderno;
        this.sendOrderDialogVisible = true;
      }
    },
    handleRadioChange(value) {
      const selectedItem = this.exhibitionNameList.find(
        (item) => item.label === value
      );
      if (selectedItem) {
        this.setValue(selectedItem.eid);
      }
    },
    setValue(id) {
      console.log("is selected" + id);
      this.selectedEid = id;
      this.getEhall();
    },
    handleSelectionChange(val) {
      this.selectedOrders = val;
    },
    setTotal() {
      this.editTotalCbm =
        this.editLength * this.editWidth * this.editHeight * this.editCount;
      this.editTotalWeight = this.editWeight * this.editCount;
    },
    openEditDialog(row) {
      this.editOrderno = row.orderno;
      this.editGoodsid = row.goodsid;
      this.editWidth = row.width;
      this.editLength = row.length;
      this.editHeight = row.height;
      this.editWeight = row.weight;
      this.editCount = row.count;
      this.editCbm = row.cbm;
      this.editTotalCbm = row.totalcbm;
      this.editTotalWeight = row.totalweight;
      this.editPackages = row.packages;
      this.editMemo = row.memo;
      this.editDialogVisible = true;
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getExhibitOrders();
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getExhibitOrders() {
      this.Loading = true;
      const token = localStorage.getItem("token");
      getExhibitOrder(
        token,
        this.page,
        this.list,
        this.ename,
        this.orderStats
      ).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          // this.volumes = res.data.data.volumes;
          this.Loading = false;
        } else {
          this.Loading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    getExhibitOrderGood(row) {
      const id = row.id;
      this.addOrderNo = row.orderno;
      const token = localStorage.getItem("token");
      getExhibitOrderGoods(token, id).then((res) => {
        if (res.data.code === 200) {
          this.goods = res.data.data;
          console.log(this.goods);
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getExhibitOrderGoodsss() {
      const token = localStorage.getItem("token");
      getExhibitOrderGoodss(token, this.selectedOrder).then((res) => {
        if (res.data.code === 200) {
          this.goodsList = res.data.data.goodsData;
          this.totalCount = res.data.data.totalCount;
          this.totalCbm = res.data.data.totalCbm;
          this.totalWeight = res.data.data.totalWeight;
          this.money = res.data.data.money;
          this.orderType = res.data.data.type;
          console.log(this.goodsList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    searchExhibitOrders() {
      this.Loading = true;
      const token = localStorage.getItem("token");
      searchExhibitOrder(token, this.orderno, this.ebusiness).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.Loading = false;
        } else {
          this.$message.error(res.data.$eventmsg);
          this.Loading = false;
        }
      });
    },
    searchExhibitOrderss() {
      this.getExhibitOrderGoodsss();
      this.Loading = true;
      const token = localStorage.getItem("token");
      searchExhibitOrder(token, this.selectedOrder, this.ebusiness).then(
        (res) => {
          if (res.data.code === 200) {
            this.orderData = res.data.data[0];
            this.orderDialogVisible = true;
            console.log(this.orderData);
            this.Loading = false;
          } else {
            this.$message.error(res.data.$eventmsg);
            this.Loading = false;
          }
        }
      );
    },
    deleteGoodsItems(row) {
      const token = localStorage.getItem("token");
      const orderno = row.orderno;
      const goodsid = row.goodsid;
      deleteGoodsItem(token, orderno, goodsid).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getExhibitOrderGood();
          this.getExhibitOrders();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    updateGoodsItems() {
      const token = localStorage.getItem("token");
      const goodsid = this.editGoodsid.toString();
      updateGoodsItem(
        token,
        this.editOrderno,
        goodsid,
        this.editWidth,
        this.editLength,
        this.editHeight,
        this.editWeight,
        this.editCount,
        this.editCbm,
        this.editTotalCbm,
        this.editTotalWeight,
        this.editPackages,
        this.editMemo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.getExhibitOrderGood();
          this.getExhibitOrders();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以需要加1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`; // 返回格式为 YYYY-MM-DD
    },
    generatePDF() {
      const pdfContent = this.$refs.pdfContent;

      // 添加loading效果
      const loading = this.$loading({
        lock: true,
        text: "正在生成PDF...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      html2canvas(pdfContent).then((canvas) => {
        const imgWidth = 683.14;
        const pageHeight = 793.7;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [imgWidth, pageHeight],
        });

        let position = 0;

        // 初始页面
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;

        // 处理分页
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }

        // 生成blob
        const pdfBlob = pdf.output("blob");
        const blobUrl = URL.createObjectURL(pdfBlob);

        // 创建预览对话框
        this.$confirm("", "订单预览", {
          dangerouslyUseHTMLString: true,
          message: `
            <div style="height: 600px;">
              <iframe 
                src="${blobUrl}" 
                width="100%" 
                height="100%" 
                frameborder="0">
              </iframe>
            </div>
          `,
          showCancelButton: true,
          cancelButtonText: "关闭",
          confirmButtonText: "打印",
          customClass: "pdf-preview-dialog",
        })
          .then(() => {
            // 点击打印按钮
            const iframe = document.querySelector(".pdf-preview-dialog iframe");
            if (iframe) {
              iframe.contentWindow.print();
            }
          })
          .catch(() => {
            // 点击关闭按钮
          })
          .finally(() => {
            // 清理blob URL
            URL.revokeObjectURL(blobUrl);
            loading.close();
          });
      });
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.exhibitionNameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getEnameListForKeys() {
      const loading = this.$loading({
        lock: true,
        text: "搜索中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getEnameListForKey(this.enameKey).then((res) => {
        if (res.data.code === 200) {
          this.enameList = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    getEhall() {
      console.log("console");
      const token = localStorage.getItem("token");
      getEhalls(token, this.selectedEid).then((res) => {
        if (res.data.code === 200) {
          this.exhibitionHallList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getUser() {
      const token = localStorage.getItem("token");
      getUsers(token, this.sphone).then((res) => {
        if (res.data.code === 200) {
          this.userList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    createServiceOrders() {
      const token = localStorage.getItem("token");
      createServiceOrder(
        token,
        // this.ephone,
        this.exhibitionName,
        this.businessExhibitionName,
        this.contacts,
        this.phone,
        this.exhibitionHall,
        this.exhibitionLocation,
        this.type,
        this.license,
        this.memo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("创建成功!");
          this.getExhibitOrders();
          this.createDialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    async batchPrintOrders() {
      if (this.selectedOrders.length === 0) {
        this.$message.warning("请至少选择一个订单");
        return;
      }

      this.batchPrintLoading = true;
      const loading = this.$loading({
        lock: true,
        text: "正在准备打印数据...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        // 获取所有选中订单的详细数据
        this.multipleOrderData = [];
        const token = localStorage.getItem("token");

        // 使用Promise.all并行获取所有订单数据
        await Promise.all(
          this.selectedOrders.map(async (order) => {
            try {
              const goodsRes = await getExhibitOrderGoodss(
                token,
                order.orderno
              );
              if (goodsRes.data.code === 200) {
                this.multipleOrderData.push({
                  orderData: order,
                  goodsList: goodsRes.data.data.goodsData,
                  totalCount: goodsRes.data.data.totalCount,
                  totalCbm: goodsRes.data.data.totalCbm,
                  totalWeight: goodsRes.data.data.totalWeight,
                  money: goodsRes.data.data.money,
                  orderType: goodsRes.data.data.type,
                });
              }
            } catch (err) {
              console.error(`获取订单 ${order.orderno} 数据失败:`, err);
            }
          })
        );

        if (this.multipleOrderData.length === 0) {
          throw new Error("没有可打印的订单数据");
        }

        loading.close();
        this.currentPrintIndex = 0;
        await this.processNextOrder();
      } catch (error) {
        loading.close();
        this.$message.error(error.message || "准备打印数据时出错");
      } finally {
        this.batchPrintLoading = false;
      }
    },

    async processNextOrder() {
      if (this.currentPrintIndex >= this.multipleOrderData.length) {
        this.$message.success("所有订单打印完成");
        return;
      }

      const currentOrder = this.multipleOrderData[this.currentPrintIndex];

      // 更新当前订单数据
      this.orderData = currentOrder.orderData;
      this.goodsList = currentOrder.goodsList;
      this.totalCount = currentOrder.totalCount;
      this.totalCbm = currentOrder.totalCbm;
      this.totalWeight = currentOrder.totalWeight;
      this.money = currentOrder.money;
      this.orderType = currentOrder.orderType;

      // 打开预览对话框并等待渲染完成
      this.orderDialogVisible = true;

      // 等待对话框打开和内容渲染
      await new Promise((resolve) => {
        this.$nextTick(() => {
          setTimeout(async () => {
            try {
              const pdfContent = this.$refs.pdfContent;
              if (!pdfContent) {
                throw new Error(
                  `无法找到订单 ${currentOrder.orderData.orderno} 的PDF内容元素`
                );
              }

              const loading = this.$loading({
                lock: true,
                text: `正在生成第 ${this.currentPrintIndex + 1}/${
                  this.multipleOrderData.length
                } 个PDF...`,
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
              });

              const canvas = await html2canvas(pdfContent, {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                logging: false,
              });

              const imgWidth = 683.14;
              const pageHeight = 793.7;
              const imgHeight = (canvas.height * imgWidth) / canvas.width;

              const pdf = new jsPDF({
                orientation: "portrait",
                unit: "px",
                format: [imgWidth, pageHeight],
              });

              pdf.addImage(
                canvas.toDataURL("image/png"),
                "PNG",
                0,
                0,
                imgWidth,
                imgHeight
              );

              const pdfBlob = pdf.output("blob");
              const blobUrl = URL.createObjectURL(pdfBlob);

              await this.$confirm("", "订单预览", {
                dangerouslyUseHTMLString: true,
                message: `
                  <div style="height: 600px;">
                    <iframe src="${blobUrl}" width="100%" height="100%" frameborder="0"></iframe>
                  </div>
                `,
                showCancelButton: true,
                cancelButtonText: "跳过",
                confirmButtonText: "打印",
                distinguishCancelAndClose: true,
                customClass: "pdf-preview-dialog",
              })
                .then(() => {
                  const iframe = document.querySelector(
                    ".pdf-preview-dialog iframe"
                  );
                  if (iframe) {
                    iframe.contentWindow.print();
                  }
                })
                .catch((action) => {
                  if (action === "cancel") {
                    this.$message.info(
                      `已跳过第 ${this.currentPrintIndex + 1} 个订单`
                    );
                  }
                });

              URL.revokeObjectURL(blobUrl);
              loading.close();
              this.orderDialogVisible = false;
              this.currentPrintIndex++;
              await this.processNextOrder();
            } catch (error) {
              console.error(
                `处理订单 ${currentOrder.orderData.orderno} 时出错:`,
                error
              );
              this.$message.error(
                `处理订单 ${currentOrder.orderData.orderno} 失败: ${error.message}`
              );
              this.orderDialogVisible = false;
              this.currentPrintIndex++;
              await this.processNextOrder();
            }
            resolve();
          }, 1000); // 给予1秒的渲染时间
        });
      });
    },
  },
  created() {
    this.getExhibitOrders();
    this.currentDate = this.getCurrentDate();
    this.getEnameLists();
  },
  computed: {
    // 动态计算需要显示的页码并过滤
    filteredPages() {
      const pages = [];
      const startPage = Math.max(2, this.currentPage - this.pageRange);
      const endPage = Math.min(
        this.total - 1,
        this.currentPage + this.pageRange
      );

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    // 是否显示左边的省略号
    showLeftEllipsis() {
      return this.currentPage - this.pageRange > 2;
    },
    // 是否显示右边的省略号
    showRightEllipsis() {
      return this.currentPage + this.pageRange < this.total - 1;
    },
  },
};
</script>

<style>
#pdfContent {
  width: 683.14px; /* 241mm */
  padding: 20px;
  background-color: #fff;
  color: #000;
  min-height: 793.7px; /* 280mm */
}

#pdfContent > div:first-child {
  flex: 1;
}

.tb {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px black solid;
}
.tbc {
  display: flex;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px black solid;
}

.tb > p {
  margin: 5px 0 5px 0;
}

.tbmain {
  min-width: 25px;
}

.el-table thead {
  color: black !important;
  font-weight: 100 !important;
}
.active-page {
  background-color: #409eff;
  color: white;
}

.page-button {
  cursor: pointer;
  margin: 0 10px;
}

/* PDF预览对话框样式 */
.pdf-preview-dialog {
  width: 80% !important;
  margin-top: 20px !important;
}

.pdf-preview-dialog .el-message-box__content {
  padding: 10px;
}

.pdf-preview-dialog .el-message-box__message {
  padding: 0;
}

.pdf-preview-dialog iframe {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
</style>
