<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="success" @click="dialogVisible = true"
        >新增租赁商品</el-button
      >
    </div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="name" label="租赁商品名称"> </el-table-column>
      <el-table-column prop="lowtime" label="最低时间"> </el-table-column>
      <el-table-column prop="hightime" label="最高时间"> </el-table-column>
      <el-table-column prop="type" label="时间单位"> </el-table-column>
      <el-table-column prop="money" label="押金价格(小时)"> </el-table-column>
      <el-table-column prop="amount" label="租赁价格(小时)"> </el-table-column>
      <el-table-column prop="out_time" label="免费时长">
        <template slot-scope="scope"> {{ scope.row.out_time }}小时 </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delCreditConfigs(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增租赁商品弹窗 -->
    <el-dialog title="新增租赁商品" :visible.sync="dialogVisible" width="30%">
      <el-form label-position="left" inline="true">
        <el-form-item label="租赁商品名称">
          <el-input v-model="add.name"></el-input>
        </el-form-item>
        <el-form-item label="最小租赁时间">
          <el-input v-model="add.lowtime" type="number"></el-input>
        </el-form-item>
        <el-form-item label="最大租赁时间">
          <el-input v-model="add.hightime" type="number"></el-input>
        </el-form-item>
        <el-form-item label="租赁时间单位">
          <el-radio-group v-model="add.type">
            <el-radio label="小时">小时</el-radio>
            <el-radio label="天">天</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支付押金价格">
          <el-input
            v-model="add.money"
            type="number"
            :step="0.01"
            :min="0.01"
          ></el-input>
        </el-form-item>
        <el-form-item label="租赁价格(小时)">
          <el-input
            v-model="add.amount"
            type="number"
            :step="0.01"
            :min="0.01"
          ></el-input>
        </el-form-item>
        <el-form-item label="免费时长(小时)">
          <el-input v-model="add.out_time" type="number" :min="1"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCreditConfigs()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改租赁商品弹窗 -->
    <el-dialog
      title="新增租赁商品"
      :visible.sync="updateDialogVisible"
      width="30%"
    >
      <el-form label-position="left" inline="true">
        <el-form-item label="租赁商品名称">
          <el-input v-model="update.name"></el-input>
        </el-form-item>
        <el-form-item label="最小租赁时间">
          <el-input v-model="update.lowtime" type="number"></el-input>
        </el-form-item>
        <el-form-item label="最大租赁时间">
          <el-input v-model="update.hightime" type="number"></el-input>
        </el-form-item>
        <el-form-item label="租赁时间单位">
          <el-radio-group v-model="update.type">
            <el-radio label="小时">小时</el-radio>
            <el-radio label="天">天</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="租赁押金价格">
          <el-input
            v-model="update.money"
            type="number"
            :step="0.01"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付押金价格">
          <el-input
            v-model="update.money"
            type="number"
            :step="0.01"
            :min="0.01"
          ></el-input>
        </el-form-item>
        <el-form-item label="租赁价格(小时)">
          <el-input
            v-model="update.amount"
            type="number"
            :step="0.01"
            :min="0.01"
          ></el-input>
        </el-form-item>
        <el-form-item label="免费时长(小时)">
          <el-input v-model="update.out_time" type="number" :min="1"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCreditConfigs()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  addCreditConfig,
  delCreditConfig,
  getCreditConfig,
  updateCreditConfig,
} from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      add: [],
      update: [],
      updateDialogVisible: false,
    };
  },
  methods: {
    openEditDialog(row) {
      this.update = row;
      this.updateDialogVisible = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getCreditConfigs() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      getCreditConfig(token)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data;
            loading.close();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
            loading.close();
          }
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          loading.close();
        });
    },
    addCreditConfigs() {
      const loading = this.$loading({
        lock: true,
        text: "正在创建",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addCreditConfig(
        token,
        this.add.name,
        this.add.lowtime,
        this.add.hightime,
        this.add.type,
        this.add.money,
        this.add.amount,
        this.add.out_time
      )
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "创建成功",
            });
            loading.close();
            this.dialogVisible = false;
            this.getCreditConfigs();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
            loading.close();
          }
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          loading.close();
        });
    },
    updateCreditConfigs() {
      const loading = this.$loading({
        lock: true,
        text: "正在修改",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      updateCreditConfig(
        token,
        this.update.id,
        this.update.name,
        this.update.lowtime,
        this.update.hightime,
        this.update.type,
        this.update.money,
        this.update.amount,
        this.update.out_time
      )
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            loading.close();
            this.updateDialogVisible = false;
            this.getCreditConfigs();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
            loading.close();
          }
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
          loading.close();
        });
    },
    delCreditConfigs(id) {
      const token = localStorage.getItem("token");
      delCreditConfig(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getCreditConfigs();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
  created() {
    this.getCreditConfigs();
  },
};
</script>

<style></style>
